* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat",sans-serif;
  color: #fff;
}

.navbar {
  overflow: hidden;
  margin-top: 30px;
  background-color: #1a1a1c;
  box-shadow: 0 2px 8px white;
  left: 0;
  right: 0;
  z-index: 1030;
  
}
.navbar a[name="bar"] {
  float: left;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  line-height: 1.5;
  font-family: Inter,Roboto,Helvetica,Arial,sans-serif;
  text-align: center;
  padding-left: 600px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 16px;
  text-decoration: none;
  background-color: transparent
  
}
.navbar a {
  float: left;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  line-height: 1.5;
  font-family: Inter,Roboto,Helvetica,Arial,sans-serif;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  background-color: black;
  
}

.dropdown {
  float: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1c;
  line-height: 1.5;
  font-family: Inter,Roboto,Helvetica,Arial,sans-serif;
  text-align: center;
  margin-left: 600px;
  padding-left: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 16px;
  text-decoration: none;
  overflow: hidden;
}

.dropdown .dropbtn {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  background-color: #1a1a1c;
  line-height: 1.5;
  font-family: Inter,Roboto,Helvetica,Arial,sans-serif;
  border: none;
  outline: none;
  padding: 14px 30px 16px;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: black;
  color: #e2e2e2;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-image: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  font-size: 15px;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color:none;
  color:blue
}

.dropdown:hover .dropdown-content {
  display: block;
}


.objecttext {
  font-size: 23px;
  margin-top: 150px;
  color:black;
  line-height: 1.2;
  margin-left: 150px;
}

.objecttext h1 {
  font-size: 28px;
  font-weight: normal;
  color: black;
}

.objecttext h2 {
  color: black;
}

.objmodel {

  color:black;
  margin-top: 100px;
  margin-bottom: 20px;
}

.objmodel label {
  color: #1a1a1c;
  padding-right: 10px;
  padding-bottom: 15px;
}

.objmodel input {
  padding-left: 20px;
}
.input {
  padding-left:10px;
  color: white;
  font-size: medium;
  margin-left: 300px;
  background-color: black;
}

.inputobj {
  padding-left:10px;
  color: white;
  font-size: medium;
  margin-left: 100px;
  background-color: black;
}
